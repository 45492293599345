<template>
  <div class="notice-page">
    <Head title="上新通知" />
    <Warpper top="40">
      <div class="notice-item">
        <span>上新通知</span>
        <button v-if="!isSubscribe" @click.stop="onSubscribe">
          订阅上新
        </button>
        <button class="active" @click.stop="onSubscribe" v-else>
          取消订阅
        </button>
      </div>
    </Warpper>
  </div>
</template>

<script>
import { reqGetNewNotice, reqSetNewNotice } from "@/network/api";

export default {
  name: "notice",
  components: {},
  created() {
    this.getSubscribe();
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo,
      isSubscribe: false,
    };
  },
  methods: {
    getSubscribe() {
      reqGetNewNotice({
        user_id: this.userInfo.id || 0,
      }).then((res) => {
        this.isSubscribe = res.backdata.notice;
      });
    },
    onSubscribe() {
      reqSetNewNotice({
        user_id: this.userInfo.id || 0,
      },false).then((res) => {
        const { code, msg, backdata } = res;
        this.$toast({ message: msg });
        if (code != 0) return;
        this.isSubscribe = res.backdata.notice;
        if (this.isSubscribe) {
          this.$MessageBox
            .alert(
              `<img style="width:160px;height:160px" src="${require("../../assets/images/qrcode.jpg")}" />`,
              "关注公众号，接收上新提醒"
            )
            .then((action) => {});
        }
      });
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.notice-item {
  height: 1.2rem;
  box-sizing: border-box;
  align-items: center;
  padding: 0 0.3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  &::after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0.3rem;
    right: 0;
    transform: scaleY(0.5);
    background: #e1e1e1;
  }
  button {
    width: 1.56rem;
    height: 0.52rem;
    cursor: pointer;
    background-color: #1c9a55;
    border-radius: 0.04rem;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    box-sizing: border-box;
    border: 0;
    &.active {
      background-color: #ffc107;
    }
    &:focus {
      outline: 0;
    }
  }
}
</style>